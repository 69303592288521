@font-face {
  font-family:  'hachette-vins';
  src:          url('../fonts/hachette-vins.eot?gpkxhf');
  src:          url('../fonts/hachette-vins.eot?gpkxhf#iefix') format('embedded-opentype'),
                url('../fonts/hachette-vins.ttf?gpkxhf') format('truetype'),
                url('../fonts/hachette-vins.woff?gpkxhf') format('woff'),
                url('../fonts/hachette-vins.svg?gpkxhf#hachette-vins') format('svg');
  font-weight:  normal;
  font-style:   normal;
  font-display: block;
}


[class^="hv-icon-"], [class*=" hv-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'hachette-vins' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.hv-icon-person:before {
  content: "\e7fd";
}
.hv-icon-person_add:before {
  content: "\e7fe";
}
.hv-icon-boutique:before {
  content: "\e900";
}
.hv-icon-cuisine:before {
  content: "\e901";
}
.hv-icon-guide:before {
  content: "\e902";
}
.hv-icon-sur_le_vin:before {
  content: "\e903";
}
.hv-icon-mail:before {
  content: "\e904";
}
.hv-icon-google:before {
  content: "\e905";
}
.hv-icon-vignerons:before {
  content: "\e90a";
}
.hv-icon-house:before {
  content: "\e906";
}
.hv-icon-single-man:before {
  content: "\e907";
}
.hv-icon-bottle:before {
  content: "\e908";
}
.hv-icon-info:before {
  content: "\e909";
}
.hv-icon-pin:before {
  content: "\e90b";
}
